/* Source: https://github.com/richleland/pygments-css/blob/master/default.css */
.codehilite .hll { background-color: #ffffcc }
.codehilite  { background: #f8f8f8; }
.codehilite .c { color: #408080; font-style: italic } /* Comment */
.codehilite .err { border: 1px solid #FF0000 } /* Error */
.codehilite .k { color: #66d9ef; font-weight: 600; } /* Keyword */
.codehilite .o { color: #ff79c6 } /* Operator = */
.codehilite .ch { color: #408080; font-style: italic } /* Comment.Hashbang */
.codehilite .cm { color: #408080; font-style: italic } /* Comment.Multiline */
.codehilite .cp { color: #BC7A00 } /* Comment.Preproc */
.codehilite .cpf { color: #408080; font-style: italic } /* Comment.PreprocFile */
.codehilite .c1 { color: #00ff00; font-style: italic } /* Comment.Single */
.codehilite .cs { color: #408080; font-style: italic } /* Comment.Special */
.codehilite .gd { color: #A00000 } /* Generic.Deleted */
.codehilite .ge { font-style: italic } /* Generic.Emph */
.codehilite .gr { color: #FF0000 } /* Generic.Error */
.codehilite .gh { color: #000080; font-weight: 600; } /* Generic.Heading */
.codehilite .gi { color: #00A000 } /* Generic.Inserted */
.codehilite .go { color: #888888 } /* Generic.Output */
.codehilite .gp { color: #000080; font-weight: 600; } /* Generic.Prompt */
.codehilite .gs { font-weight: 600; } /* Generic.Strong */
.codehilite .gu { color: #800080; font-weight: 600; } /* Generic.Subheading */
.codehilite .gt { color: #0044DD } /* Generic.Traceback */
.codehilite .kc { color: #f0dfaf; font-weight: 600; } /* Keyword.Constant */
.codehilite .kd { color: #f0dfaf; font-weight: 600; } /* Keyword.Declaration */
.codehilite .kn { color: #f0dfaf; font-weight: 600; } /* Keyword.Namespace */
.codehilite .kp { color: #f0dfaf } /* Keyword.Pseudo */
.codehilite .kr { color: #f0dfaf; font-weight: 600; } /* Keyword.Reserved */
.codehilite .kt { color: #B00040 } /* Keyword.Type */
.codehilite .m { color: #666666 } /* Literal.Number */
.codehilite .s { color: #a5f3fc } /* Literal.String */
.codehilite .na { color: #f8f8f2 } /* Name.Attribute */
.codehilite .nb { color: #f0dfaf } /* Name.Builtin */
.codehilite .nc { color: #a6e22e; font-weight: 600; } /* Name.Class */
.codehilite .no { color: #880000 } /* Name.Constant */
.codehilite .nd { color: #AA22FF } /* Name.Decorator */
.codehilite .ni { color: #999999; font-weight: 600; } /* Name.Entity */
.codehilite .ne { color: #D2413A; font-weight: 600; } /* Name.Exception */
.codehilite .nf { color: #dcdccc } /* Name.Function */
.codehilite .n { color: #f8f8f2 } /* Name.Function */
.codehilite .nl { color: #A0A000 } /* Name.Label */
.codehilite .nn { color: #dcdccc; } /* Name.Namespace */
.codehilite .nt { color: #f0dfaf; font-weight: 600; } /* Name.Tag */
.codehilite .nv { color: #19177C } /* Name.Variable */
.codehilite .ow { color: #AA22FF; font-weight: 600; } /* Operator.Word */
.codehilite .w { color: #bbbbbb } /* Text.Whitespace */
.codehilite .mb { color: #ffb86c } /* Literal.Number.Bin */
.codehilite .mf { color: #ffb86c } /* Literal.Number.Float */
.codehilite .mh { color: #ffb86c } /* Literal.Number.Hex */
.codehilite .mi { color: #ffb86c } /* Literal.Number.Integer */
.codehilite .mo { color: #ffb86c } /* Literal.Number.Oct */
.codehilite .sa { color: #a5f3fc } /* Literal.String.Affix */
.codehilite .sb { color: #a5f3fc } /* Literal.String.Backtick */
.codehilite .sc { color: #a5f3fc } /* Literal.String.Char */
.codehilite .dl { color: #a5f3fc } /* Literal.String.Delimiter */
.codehilite .sd { color: #a5f3fc; font-style: italic } /* Literal.String.Doc */
.codehilite .s2 { color: #a5f3fc } /* Literal.String.Double */
.codehilite .se { color: #BB6622; font-weight: 600; } /* Literal.String.Escape */
.codehilite .sh { color: #a5f3fc } /* Literal.String.Heredoc */
.codehilite .si { color: #BB6688; font-weight: 600; } /* Literal.String.Interpol */
.codehilite .sx { color: #f0dfaf } /* Literal.String.Other */
.codehilite .sr { color: #BB6688 } /* Literal.String.Regex */
.codehilite .s1 { color: #a5f3fc } /* Literal.String.Single */
.codehilite .ss { color: #19177C } /* Literal.String.Symbol */
.codehilite .bp { color: #f0dfaf } /* Name.Builtin.Pseudo */
.codehilite .fm { color: #dcdccc } /* Name.Function.Magic */
.codehilite .vc { color: #19177C } /* Name.Variable.Class */
.codehilite .vg { color: #19177C } /* Name.Variable.Global */
.codehilite .vi { color: #19177C } /* Name.Variable.Instance */
.codehilite .vm { color: #19177C } /* Name.Variable.Magic */
.codehilite .il { color: #ffb86c } /* Literal.Number.Integer.Long */
